<template>
  <el-card shadow="hover" header="订单详情" class="x-card-title" v-loading="loading">
    <div slot="header" class="button_back">
      <span>订单详情</span>
      <el-button style="float: right;" size="small" @click="() => { $router.go(-1); }" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <el-form class="details" label-position="left">
      <el-form-item label="收货地址">
        {{ orderDetail.ship_province }}
        {{ orderDetail.ship_city }}
        {{ orderDetail.ship_county }}
        {{ orderDetail.ship_town || '' }}
        {{ orderDetail.ship_addr }}
      </el-form-item>
      <el-form-item label="收货人">
        {{ orderDetail.ship_name }}
      </el-form-item>
      <el-form-item label="联系电话">
        {{ orderDetail.ship_mobile }}
      </el-form-item>
      <el-form-item label="订单编号">
        {{ orderDetail.sn }}
      </el-form-item>
      <el-form-item label="付款方式">
        {{ orderDetail.payment_method_name }}
      </el-form-item>
      <el-form-item label="下单时间">
        {{ orderDetail.create_time | unixToDate }}
      </el-form-item>
      <el-form-item label="商品总价">
        {{ orderDetail.goods_price | unitPrice('¥') }}
      </el-form-item>
<!--      <el-form-item label="运费">-->
<!--        {{ orderDetail.shipping_price | unitPrice('¥') }}-->
<!--      </el-form-item>-->
      <el-form-item label="订单总价">
        {{ orderDetail.goods_price + orderDetail.shipping_price | unitPrice('¥') }}
      </el-form-item>
      <el-form-item label="商品信息">
        <en-table-layout
          :pagination="false"
          :toolbar="false"
          :table-data="pageData.data"
          style="width: 70vw; display: inline-block; margin-top: 12px"
        >
          <template slot="table-columns">
            <el-table-column prop="goods_image" label="商品图片">
              <template slot-scope="scope">
                <img :src="scope.row.goods_image" class="goods-image" alt=""/>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称"></el-table-column>
            <el-table-column prop="purchase_price" label="会员价">
              <template slot-scope="scope">
                {{ scope.row.purchase_price | unitPrice('￥') }}
              </template>
            </el-table-column>
            <el-table-column prop="shop_purchase_price" label="销售价">
              <template slot-scope="scope">
                {{ (scope.row.shop_purchase_price || 0) | unitPrice('￥') }}
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量"></el-table-column>
            <el-table-column prop="shop_subtotal" label="总价">
              <template slot-scope="scope">
                {{ scope.row.shop_subtotal | unitPrice('￥') }}
              </template>
            </el-table-column>
            <el-table-column prop="ship_status" label="物流状态">
              <template slot-scope="{row}">
                <span v-if="(row.state == null || row.state==0)&&orderDetail.logistics_status == 1">拣货中</span>
                <span v-else>{{ row.state === 0 || row.state==null ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </el-form-item>
    </el-form>

  </el-card>
</template>

<script>
import * as API_order from '@/api/order';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'detail',
  components: {EnTableLayout},
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      orderDetail: {},

      /* 商品信息数组 */
      pageData: {data: []},

      /*  订单编号 */

      sn: '',

      route: ''
    };
  },
  filters: {},
  beforeRouteUpdate(to, from, next) {
    this.sn = to.params.sn;
    this.GET_OrderDetail();
    next();
  },
  mounted() {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
    this.route = this.$route.path.split('/')[2];
  },
  activated() {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
  },
  methods: {
    /** 获取订单详情信息 */
    GET_OrderDetail() {
      this.loading = true;
      API_order.getAccountFlowId(this.sn).then(res => {
        this.orderDetail = res.order_do;
        this.pageData.data = JSON.parse(res.order_do.items_json);
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .details {
  .el-form-item {
    margin-bottom: 0;

    .el-form-item__label {
      font-weight: bold;
    }
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont > tbody > tr > td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.button_back {
  height: 25px;
  line-height: 25px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;
    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table--border {
  /* border: 1px solid #000000 !important; */
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}
</style>

